<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.first_name + ' ' + userData.last_name)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.first_name + ' ' + userData.last_name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'profile-update-info'}"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="auto"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Full name:</span>
            </th>
            <td class="pb-50">
              {{ userData.first_name }} {{ userData.last_name }}
            </td>
          </tr>
          <tr v-if="userData.addresses.country">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country:</span>
            </th>
            <td class="pb-50">
              {{ userData.addresses.country.name }}
            </td>
          </tr>
          <tr v-if="userData.state">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">State:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.addresses.state }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="MapIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.addresses.city }}
            </td>
          </tr>
          <tr v-if="userData.addresses.postcode">
            <th class="pb-50 pr-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Postcode:</span>
            </th>
            <td class="pb-50">
              {{ userData.addresses.postcode }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address 1:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.addresses.address1 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address 2:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.addresses.address2 }}
            </td>
          </tr>
          <tr>
            <th class="pb-50 pr-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telephone 1:</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ telephone1 }}
            </td>
          </tr>
          <tr v-if="telephone2">
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Telephone 2:</span>
            </th>
            <td>
              {{ telephone2 }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      phone_country_code1: phoneCountryCode1, phone_number1: phoneNumber1, phone_country_code2: phoneCountryCode2, phone_number2: phoneNumber2,
    } = props.userData
    const telephone1 = `${phoneCountryCode1 ? `+${phoneCountryCode1}` : ''}${phoneNumber1 ?? ''}`
    const telephone2 = `${phoneCountryCode2 ? `+${phoneCountryCode2}` : ''}${phoneNumber2 ?? ''}`
    return {
      avatarText,
      telephone1,
      telephone2,
    }
  },
}
</script>

<style>

</style>
