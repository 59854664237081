<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
        >
          <user-view-user-info-card
            :user-data="userData"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        />
      </b-row>
    </template>

  </div>
</template>

<script>

import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import { mapMutations } from 'vuex'
import UserViewUserInfoCard from '../components/UserViewUserInfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    // Local Components
    UserViewUserInfoCard,

  },
  data() {
    return {
      userData: null,
    }
  },
  async created() {
    try {
      const { data } = await this.$http.get('/user/profile/show')
      const oldUserData = JSON.parse(localStorage.getItem('userData'))

      this.userData = data.data

      this.SET_USER_DATA({
        ...oldUserData,
        ...data.data,
      })
    } catch (error) {
      console.log('error :>> ', error)
    }
  },
  methods: {
    ...mapMutations('account', ['SET_USER_DATA']),
  },
}
</script>

<style>

</style>
